
.goods-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 200px;
    .add-goods {
        margin-top: 20px;
        .searchInput {
            ::v-deep .el-input-group__append {
                padding: 0 8px;
                background: #2DC079;
                .el-button {
                    i {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .customTable {
        .goods-info {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
                margin-right: 10px;
            }
            .goods-name {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: 16px;
            }
        }
        .a-link {
            font-size: 16px;
        }
        ::v-deep {
            .el-table__empty-block {
                background-image: url("../../../../assets/images/student/goods-null-data.png");
                background-repeat: no-repeat;
                background-position: center;
                .el-table__empty-text {
                    margin-top: 150px;
                }
            }
        }
    }
}
